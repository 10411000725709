import { Avatar, Badge, Button, Flex, Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import { Link, useLocation, useNavigate, useRoutes } from "react-router-dom";
import { GroupOutlined, HomeOutlined, MessageOutlined, PlusCircleOutlined, PlusOutlined, SettingFilled, TeamOutlined } from '@ant-design/icons';
import Sidebar from "./Sidebar";
import { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import Transactions from "../Transactions";
import Humans from "../Humans";
import Assets from "../Assets";
import Services from "../Services";
import CountUp from "react-countup";
import Contracts from "../Contracts";
import CreateProject from '../CreateProject';
import { generateHexDarkColor, generateHexLightColor, get, livekitServerUrl, patch, slugify, sse } from "../../services/api";
import { addListToProject, addProject, deleteListFromProject, deleteProject, setProjects, updateListInProject, updateProject } from "../../features/projects";
import { addNotification, setNotifications } from "../../features/notifications";
import Project from "../Project";
import Report from "../Report";
import List from '../List';
import { addUser, deleteUser, setUsers, updateUser } from "../../features/users";
import useSound from 'use-sound';
import notificationSound from './notification.mp3';
import Everything from "../Everything";
import { setHumans } from "../../features/humans";
import { setAssets } from "../../features/assets";
import { setServices } from "../../features/services";
import { mayRemoveCurrentTimeLog, setCurrentTimeLog } from "../../features/currentTimeLog";
import Attendance from '../Attendance';
import Profile from '../Profile';
import Settings from '../Settings';
import { setUser } from "../../features/user";
import HeaderItem from "../../components/HeaderItem";
import Player from "../Player";
import Meeting from "../Meeting";
import { LiveKitRoom } from "@livekit/components-react";
import AttendedTasks from "../AttendedTasks";
import { addTaskEvent } from '../../features/taskEvents';
import { addMeetingEvent } from '../../features/meetingEvent';
import { addListAccessToList, deleteListAccessFromList, updateListAccessInList } from "../../features/list";

const menuItem = (label, path, element, hidden) => {
  if (label) {
    return {
      label,
      path,
      element,
      hidden
    }
  } else {
    return null;
  }
}
const projectGroupItem = () => {
  return {
    label: <span style={{ display: "flex", justifyContent: "space-between" }}>
      <span>Projects</span>
      <Link to={"/create/project"}><PlusCircleOutlined /></Link>
    </span>,
    path: "/create/project",
    type: 'group',
    element: <CreateProject />
  }
}

const profileMenu = (user) => {
  if (user.roles.indexOf("admin") >= 0) {
    return {
      icon: <SettingFilled />,
      label: "More",
      path: "/sample",
      children: [
        menuItem('Attendance', "/sample/attendance", <Attendance />),
        menuItem('Contracts', "/sample/contracts", <Contracts />),
        menuItem('Humans', "/sample/humans", <Humans />),
        menuItem('Assets', "/sample/assets", <Assets />),
        menuItem('Services', "/sample/services", <Services />),
        menuItem('Transactions', "/sample/transactions", <Transactions />),
        menuItem('Profile', "/sample/profile", <Profile />),
        menuItem('Reports', "/sample/reports", <Report />),
        menuItem('Settings', "/sample/settings", <Settings />),
      ]
    }
  }
  return {
    icon: <SettingFilled />,
    label: "More",
    path: "/sample",
    children: [
      menuItem('Attendance', "/sample/attendance", <Attendance />),
      menuItem('Profile', "/sample/profile", <Profile />),
      menuItem('Settings', "/sample/settings", <Settings />),
    ]
  }
}

const listItem = (project, list, navigate, location) => {
  const openChat = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (location.pathname !== "/" + slugify(project.name) + "/" + slugify(list.name)) {
      navigate("/" + slugify(project.name) + "/" + slugify(list.name), {
        state: {
          chat: true
        }
      });
    } else {
      navigate("/" + slugify(project.name) + "/" + slugify(list.name), {
        state: {
          chat: !location.state.chat
        },
        replace: true
      });
    }
  }
  return menuItem(
    <span className="menu-listItem">
      {list.name}
      <span className="menu-listItem-actions">
        {list.notifications ? <Badge size="small" count={list.notifications} onClick={openChat}>
          <MessageOutlined className="menu-listItem-chat" />
        </Badge> : <MessageOutlined className="menu-listItem-chat" onClick={openChat} />}
      </span>
    </span>,
    "/" + slugify(project.name) + "/" + slugify(list.name),
    <List list={list} project={project} />
  )
}

const projectItem = (project, navigate, location) => {
  const openProject = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate("/" + slugify(project.name))
  }
  const openUnreadChats = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const list = project.lists.find(l => l.notifications);
    navigate("/" + slugify(project.name) + "/" + slugify(list.name), {
      state: {
        chat: true
      }
    });
  }
  const unreadCount = project.lists.reduce((v, l) => {
    return l && l.notifications ? l.notifications + v : 0 + v
  }, 0);
  const item = menuItem(
    <span className="menu-projectItem">
      <Flex align="center" style={{ position: 'absolute', left: '5px', gap: "5px" }}>
        {/* <Avatar shape="circle" size={8} style={{ backgroundColor: generateHexLightColor(project.id.toString()), position: 'relative', top: '-1px', left: '-5px', }} /> */}
        <Avatar size={"small"} src={"https://static.idriveonlinebackup.com/include/images/favicon.ico"} />
        {project.name}
      </Flex>
      <span className="menu-projectItem-actions">
        <PlusOutlined className="menu-projectItem-setting" onClick={openProject} />
        {unreadCount > 0 && <Badge size="small" count={unreadCount} onClick={openUnreadChats}>
          <MessageOutlined className="menu-projectItem-chat" />
        </Badge>}
      </span>
    </span>,
    "/" + slugify(project.name)
  );
  const lists = project?.lists?.filter(list => list && list.id);
  if (lists && lists.length) {
    delete item.element;
    item.children = lists.map((list) => listItem(project, list, navigate, location));
  } else {
    item.children = [];
  }
  item.children = [
    ...item.children,
    menuItem("Overview", "/" + slugify(project.name), <Project project={project} />, true),
  ]
  return item;
};

const homeMenu = (projects, navigate, location) => {
  return {
    icon: <HomeOutlined />,
    label: "Home",
    path: "/",
    children: [
      menuItem(<><GroupOutlined />&nbsp;Tasks</>, '/tasks', <AttendedTasks />),
      menuItem(<><GroupOutlined />&nbsp;Everything</>, '/everything', <Everything />),
      menuItem(<><TeamOutlined />&nbsp;Club</>, '/club', <Player />),
      menuItem(<><TeamOutlined />&nbsp;Meeting</>, '/meeting/:id', <Meeting />, true),
      projectGroupItem(),
      ...projects.map((a) => {
        return projectItem(a, navigate, location)
      })
    ],
  }
}

export default function AppLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const [ready, setReady] = useState(false);
  const [token, setToken] = useState(null);
  const room = useSelector((state) => state.room);
  const projects = useSelector((state) => state.projects);
  const notifications = useSelector((state) => state.notifications);
  window.notifi = new Audio(notificationSound);
  const links = [
    homeMenu(projects, navigate, location),
    profileMenu(user)
  ];
  const content = useRoutes(links);
  const refreshProjects = async () => {
    const projects = await get(`users/${user.id}/projects`);
    return projects;
  };
  const refreshHumans = async () => {
    const humans = await get(`/humans`, {
      include: [{
        relation: "transactions",
        scope: {
          limit: 1,
          order: 'endAt DESC'
        }
      }]
    });
    return humans;
  };
  const refreshAssets = async () => {
    const assets = await get(`/assets`, {
      include: [{
        relation: "transactions",
        scope: {
          fields: ["amount", "assetId"]
        }
      }]
    });
    return assets;
  };
  const refreshServices = async () => {
    const services = await get(`/services`, {
      include: [{
        relation: "transactions",
        scope: {
          limit: 1,
          order: 'endAt DESC'
        }
      }]
    });
    return services;
  };
  const refreshCurrentTimeLog = async () => {
    const timeLog = await get(`users/${user.id}/time-logs`, {
      where: { stoppedAt: null },
      include: [{
        relation: 'task',
        scope: {
          include: [{
            relation: 'list',
            scope: {
              include: [{
                relation: 'project'
              }]
            }
          }]
        }
      }]
    });
    return timeLog[0];
  };
  const refreshUsers = async () => {
    const users = await get("/users");
    return users;
  };
  const refreshMeetings = async () => {
    const meetings = await get(`/users/${user.id}/meetings`, {
      include: [
        { relation: 'participants' },
        { 
          relation: 'task',
          scope: {
            include: [
              { relation: 'taskAccesses' },
              { relation: 'list', scope: { include: [{ relation: 'project' }] } }
            ]
          }
        }
      ]
    });
    return meetings;
  };
  const handleWorkStatus = async (status) => {
    if (status === "out") {
      // await patch(`users/${user.id}/human`, { checkedIn: false, onBreak: false });
      await handleLogout();
    } else if (status === "break") {
      await patch(`users/${user.id}/human`, { checkedIn: true, onBreak: true });
    } else if (status === "working") {
      await patch(`users/${user.id}/human`, { checkedIn: true, onBreak: false });
    }
    dispatch(setUser({ ...user, status: status }));
  }
  const handleLogout = async () => {
    await patch(`users/${user.id}/human`, { checkedIn: false, onBreak: false });
    console.log('Logout action triggered');
    localStorage.clear();
    window.location.href = ("/");
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const [
          users, 
          projects, 
          humans, 
          assets, 
          services, 
          currentTimeLog, 
          meetings
        ] = await Promise.all([
          refreshUsers(),
          refreshProjects(),
          refreshHumans(),
          refreshAssets(),
          refreshServices(),
          refreshCurrentTimeLog(),
          refreshMeetings()
        ]);
        // Dispatch all data at once after fetching is complete
        dispatch(setUsers(users));
        dispatch(setProjects(projects));
        dispatch(setHumans(humans));
        dispatch(setAssets(assets));
        dispatch(setServices(services));
        dispatch(setCurrentTimeLog(currentTimeLog));
        meetings.forEach((meeting) => {
          dispatch(addNotification({ ...meeting, type: 'meeting' }));
        });
        setReady(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
    const eventSource = sse(user.id);
    eventSource.onmessage = async (event) => {
      const message = JSON.parse(event.data);
      switch (message.entity) {
        case 'Message':
          switch (message.method) {
            case 'POST':
              if(message.data.userId !== window.user.id) { window.notifi.play(); }
              dispatch(addNotification(message.data));
              break;
            default:
              break;
          }
          break;
        case 'Meeting':
          switch (message.method) {
            case 'POST':
              if(message.data.userId !== window.user.id) { window.notifi.play(); }
              message.data.type = 'meeting';
              dispatch(addNotification(message.data));
              break;
            default:
              dispatch(addMeetingEvent(message));
              break;
          }
          break;
        case 'User':
          console.log('user event');
          switch (message.method) {
            case 'POST':
              dispatch(addUser(message.data));
              break;
            case 'PUT':
              dispatch(updateUser(message.data));
              break;
            case 'DELETE':
              dispatch(deleteUser({ id: message.data.id }));
              break;
            default:
              break;
          }
          break;
        case 'Project':
          console.log('project event');
          switch (message.method) {
            case 'POST':
              dispatch(addProject(message.data));
              break;
            case 'PUT':
              dispatch(updateProject(message.data));
              break;
            case 'DELETE':
              dispatch(deleteProject({ id: message.data.id }));
              break;
            default:
              break;
          }
          break;
        case 'List':
          console.log('list event');
          switch (message.method) {
            case 'POST':
              dispatch(addListToProject(message.data));
              break;
            case 'PUT':
              dispatch(updateListInProject(message.data));
              break;
            case 'DELETE':
              dispatch(deleteListFromProject(message.data));
              break;
            default:
              break;
          }
          break;
        case 'ListAccess':
          console.log('list access event');
          switch (message.method) {
            case 'POST':
              dispatch(addListAccessToList(message.data));
              break;
            case 'PUT':
              dispatch(updateListAccessInList(message.data));
              break;
            case 'DELETE':
              dispatch(deleteListAccessFromList(message.data));
              break;
            default:
              break;
          }
          break;
        case 'Task':
        case 'TaskAccess':
        case 'TimeLog':
          if(message.entity == 'TimeLog' && message.data.userId == window.user.id) {
            message.method == 'POST' && !message.data.stoppedAt && dispatch(setCurrentTimeLog(message.data));
            message.method == 'PUT' && message.data.stoppedAt && dispatch(mayRemoveCurrentTimeLog(message.data));
          }
          dispatch(addTaskEvent({...message, id: Date.now()}));
          break;
        default:
          break;
      }
    };
    return () => {
      eventSource.close();
    };
  }, []);

  useEffect(() => {
    dispatch(setProjects([
      ...projects.map(p => {
        return {
          ...p,
          lists: p.lists.map(l => {
            return l && l.id ? {
              ...l,
              notifications: notifications.filter(n => n.listId === l.id).length
            } : null
          })
        }
      })
    ]));
  }, [notifications]);

  useEffect(() => {
    if (room) {
      get(`/meeting_token?roomName=${room}&participantName=${user.id}`).then((data) => {
        setToken(data.token);
      });
    }
  }, [room]);

  if (user.status === "break") {
    return <Flex align="center" justify="center" vertical style={{ height: "100vh" }}>
      <div style={{ flex: 0, marginBottom: "20px" }}>
        Welcome, {user.firstName} {user.lastName}
      </div>
      <Flex align="center" justify="center" style={{ height: "100vh", flex: 0 }} gap={20}>
        <Button
          style={{ backgroundColor: "#518f51", fontWeight: "bold" }}
          type="primary"
          onClick={() => { handleWorkStatus("working") }}
        >Continue</Button>
        <Button
          style={{ backgroundColor: "#af5151", fontWeight: "bold" }}
          type="primary"
          onClick={() => { handleWorkStatus("out") }}
        >Check Out</Button>
      </Flex>
    </Flex>
  }

  if (user.status === "out") {
    return <Flex align="center" justify="center" vertical style={{ height: "100vh" }}>
      <div style={{ flex: 0, marginBottom: "20px" }}>
        Welcome, {user.firstName} {user.lastName}
      </div>
      <Flex align="center" justify="center" style={{ height: "100vh", flex: 0 }} gap={20}>
        <Button
          style={{ fontWeight: "bold" }}
          type="primary"
          onClick={() => { handleWorkStatus("working") }}
        >Check In</Button>
        <Button
          style={{ backgroundColor: "#af5151", fontWeight: "bold" }}
          type="primary"
          onClick={() => { handleLogout() }}
        >Logout</Button>
      </Flex>
    </Flex>
  }

  return ready && <LiveKitRoom serverUrl={livekitServerUrl} token={token} audio={false} options={{
    publishDefaults: {
      videoCodec: 'av1',
      simulcast: false,
    },
    adaptiveStream: false
  }}>
    <Layout style={{ height: "100vh" }} hasSider>
      <Sidebar links={links} key={projects.length ? 1 : 0} />
      <Layout>
        <HeaderItem handleWorkStatus={handleWorkStatus} />
        <Content key={projects.length}>
          <AnimatePresence mode="wait" >
            <motion.div
              style={{ height: "100%" }}
              key={location.pathname}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              {content}
            </motion.div>
          </AnimatePresence>
        </Content>
      </Layout>
    </Layout>
  </LiveKitRoom>
}