import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get, patch } from '../services/api';
import { Button, DatePicker, Flex, Form, Input, Modal, Select, Table, Tooltip } from 'antd'; // Assuming we're using some UI library for layout
import moment from 'moment/moment';
import dayjs from 'dayjs';

// Function to convert date-time to minutes since start of the day
const getMinutesSinceStartOfDay = (dateTime) => {
  const date = dateTime ? new Date(dateTime) : new Date();
  return date.getUTCHours() * 60 + date.getUTCMinutes();
};

const Timeline = ({ events, human, editAttendance }) => {
  const mouseEnter = (event) => {
    console.log('mouseEnter');
    var array = document.getElementsByClassName('ev-'+event.eventId);
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      element.classList.add('hover');
    }
  }

  const mouseLeave = (event) => {
    console.log('mouseLeave');
    var array = document.getElementsByClassName('ev-'+event.eventId);
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      element.classList.remove('hover');
    }
  }

  const startMinutes = getMinutesSinceStartOfDay(`1970-01-01T${human.idealCheckIn}Z`);
  const endMinutes = getMinutesSinceStartOfDay(`1970-01-01T${human.idealCheckOut}Z`);
  const duration = endMinutes - startMinutes;
  // console.log({event, duration});
  const widthPercentage = (duration / 1440) * 100;
  const leftPercentage = (startMinutes / 1440) * 100;

  return (
    <div className="timeline-container">
      <div className='workTime' style={{
        left: `${leftPercentage}%`,
        width: `${widthPercentage}%`,
      }}></div>
      {events.map((event) => {
        const startMinutes = getMinutesSinceStartOfDay(event.startAt);
        const endMinutes = getMinutesSinceStartOfDay(event.endAt);
        const duration = endMinutes - startMinutes;
        // console.log({event, duration});
        const widthPercentage = (duration / 1440) * 100;
        const leftPercentage = (startMinutes / 1440) * 100;
        return (
          <Tooltip key={event.id} title={
            <small>{moment(event.startAt).toString()} <br/> {event.endAt ? moment(event.endAt).toString() : 'Now'}</small>
          }>
            <div key={event.id}
              className={`timeline-event ${event.event} ev-${event.eventId}`}
              onMouseEnter={() => mouseEnter(event)}
              onMouseLeave={() => mouseLeave(event)}
              onClick={() => {editAttendance(event)}}
              style={{
                left: `${leftPercentage}%`,
                width: `${widthPercentage}%`,
              }}
            ></div>
          </Tooltip>
        );
      })}
    </div>
  );
};

const generateDateRange = (startDate, endDate) => {
  const dates = [];
  let currentDate = dayjs(startDate);
  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
    console.log("mello");
    dates.push(currentDate.format('ddd, MMM D'));
    currentDate = currentDate.add(1, 'day');
  }
  return dates;
};

export default function Attendance() {
  const user = useSelector((state) => state.user);
  const [human, setHuman] = useState(null);
  const [attendances, setAttendances] = React.useState([]);
  const [attendance, setAttendance] = React.useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [idealDuration, setIdealDuration] = useState(0);
  const today = dayjs();
  const startOfLastMonth = today.subtract(1, 'month').startOf('month'); // Start of last month
  const endOfToday = today; // Today
  const allDates = generateDateRange(startOfLastMonth, endOfToday);

  const editAttendance = (event) => {
    setIsEditModalOpen(true);
    console.log(event);
    setAttendance({
      id: event.eventId || event.id,
      event: event.event,
      endAt: event.orgEndAt || event.endAt,
      startAt: event.orgStartAt || event.startAt,
      userId: event.userId
    })
  }

  const onEditAttendance = async (values) => {
    console.log({values});
    const resp = await patch(`/users/${user.id}/attendances`, values, { id: attendance.id });
    setAttendance(null);
    handleCancel();
    getAttendances();
  }

  const handleCancel = () => {
    setIsEditModalOpen(false);
    setIsAddModalOpen(false);
    setAttendance(null);
  }

  const getAttendances = async () => {
    get(`/users/${window.location.hash.slice(1) || user.id}/attendances`).then((data) => {
      for (let i = 0; i < data.length; i++) {
        const event = data[i];
        if(!event.endAt) { event.endAt = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'); }
        if(!event.eventId) { event.eventId = event.id }
        if(!event.orgEndAt) { event.orgEndAt = event.endAt }
        if(!event.orgStartAt) { event.orgStartAt = event.startAt }
        // if(event.id == 51 || event.eventId == 51) {
        //   console.log(event.id, event.startAt, event.endAt);
        //   console.log(moment(event.endAt).utc().format('YYYYDDD'), moment(event.startAt).utc().format('YYYYDDD'))
        // }
        // console.log("hello");
        if(moment(event.endAt).utc().format('YYYYDDD') != moment(event.startAt).utc().format('YYYYDDD') && moment(event.endAt).utc() > moment(event.startAt).utc()) {
          data.splice(i + 1, 0, {
            ...event,
            id: Math.random().toString(),
            eventId: event.eventId,
            startAt: moment(event.startAt).utc().endOf('day').add(1, 'second').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          });
          event.endAt = moment(event.startAt).utc().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        }
        // console.log(event)
        // if(i > 100) { break; }
      }
      setAttendances(data);
    });
  }

  useEffect(() => {
    get(`/users/${window.location.hash.slice(1) || user.id}/human`).then((human) => {
      setHuman(human);
      const startMinutes = getMinutesSinceStartOfDay(`1970-01-01T${human.idealCheckIn}Z`);
      const endMinutes = getMinutesSinceStartOfDay(`1970-01-01T${human.idealCheckOut}Z`);
      setIdealDuration(endMinutes - startMinutes);
    })
    getAttendances();
  }, [user.id]);

  const groupedEvents = attendances.reduce((acc, event) => {
    const date = dayjs(event.startAt).utc().format('ddd, MMM D');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(event);
    return acc;
  }, {});
  // Ensure all dates are present in groupedEvents
  allDates.forEach((date) => {
    if (!groupedEvents[date]) {
      groupedEvents[date] = []; // Add empty array for dates with no events
    }
  });
  allDates.reverse();

  const columns = [
    {
      title: 'Date',
      width: '200px',
      render: ({ date }) => {
        return (
          <div className="heading-with-tagline">
            <a href="#">{date}</a>
            <span></span>
          </div>
        );
      },
    },
    {
      title: 'Presence',
      width: '200px',
      render: ({ date, events }) => {
        const time = { checkedin: 0, break: 0, tracking: 0 };
        events.forEach(event => {
          const startMinutes = getMinutesSinceStartOfDay(event.startAt);
          const endMinutes = getMinutesSinceStartOfDay(event.endAt);
          const duration = endMinutes - startMinutes;
          time[event.event] += duration;
        });
        let content = <></>;
        if (date == dayjs().utc().format('ddd, MMM D')) {
          content = <span>Today</span>
        } else if (date.includes("Sun")) {
          content = <span>----- Holiday -----</span>
        } else if (time.checkedin > 1400) { // Marked absent for the person who leave checkedin for whole day 
          content = <Tooltip title={'Marked absent for the person who leave checkedin for whole day '}><span style={{ color: "#ff4d4f" }}>Unpaid Leave</span></Tooltip>
        } else if (time.checkedin < idealDuration/2) { // Marked absent for the person who is not checkedin more than half of the ideal duration
          content = <Tooltip title={'Marked absent for the person who is not checkedin more than half of the ideal duration'}><span style={{ color: "#ff4d4f" }}>Unpaid Leave</span></Tooltip>
        } else if (time.checkedin < idealDuration) { // Marked half day for the person who is checkedin less than the ideal duration
          content = <Tooltip title={'Marked half day for the person who is checkedin less than the ideal duration'}><span style={{ color: "#ff4d4f" }}>Unpaid Half Day</span></Tooltip>
        } else if (time.tracking < 0.1*idealDuration) { // Marked absent for the person who does not track 10% of the ideal time
          content = <Tooltip title={'Marked absent for the person who does not track 10% of the ideal time'}><span style={{ color: "#ff4d4f" }}>Unpaid Leave</span></Tooltip>
        } else if (time.tracking > 960) { // Marked absent for the person who track more than 16 hrs a day
          content = <Tooltip title={'Marked absent for the person who track more than 16 hrs a day'}><span style={{ color: "#ff4d4f" }}>Unpaid Leave</span></Tooltip>
        } else { // Marked absent for the person who track more than 16 hrs a day
          content = <Tooltip title={'Present'}><span style={{ color: "#3782f4" }}>Present</span></Tooltip>
        }
        return (
          <div className="heading-with-tagline">
            <a href="#">
              {content}
            </a>
            <span></span>
          </div>
        );
      },
    },
    {
      title: 'Work Diary',
      render: ({ events }) => {
        return (
          <div className="heading-with-tagline">
            <Timeline events={events} human={human} editAttendance={editAttendance}/>
          </div>
        );
      },
    },
  ];

  const dataSource = allDates.map((date) => ({
    date,
    events: groupedEvents[date],
  }));

  return (
    <Flex gap={20}>
      {human && <Table
        style={{ flex: '1' }}
        scroll={{ y: window.innerHeight - 120 }}
        pagination={false}
        dataSource={dataSource}
        rowKey={'date'}
        columns={columns}
      />}
      {/* {attendance && <Modal title="Edit Attendance" open={isEditModalOpen} onCancel={handleCancel} footer={[ <Button type="primary" className="bold" form="editAttendanceForm" key="submit" htmlType="submit"> Submit </Button> ]}>
        <Form
          id="editAttendanceForm"
          name="basic"
          layout="vertical"
          // onValuesChange={handleFormValuesChange}
          onFinish={onEditAttendance}
          // onFinishFailed={onFinishFailed}
          autoComplete="off">
          <Flex gap={'middle'}>
            <Form.Item
              style={{ flex: 1 }}
              label="event"
              name="event"
              initialValue={attendance.event}
              rules={[{
                required: true,
                message: 'Please input attendance startAt!',
              }]}>
              <Select>
                <Select.Option value={'checkedin'}>Checked In</Select.Option>
                <Select.Option value={'break'}>Break</Select.Option>
                <Select.Option value={'tracking'}>Tracking</Select.Option>
              </Select>
            </Form.Item>
          </Flex>
          <Flex gap={'middle'}>
            <Form.Item
              style={{ flex: 1 }}
              label="startAt"
              name="startAt"
              initialValue={dayjs(attendance.startAt)}
              rules={[{
                required: true,
                message: 'Please input attendance startAt!',
              }]}>
              <DatePicker showTime style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              style={{ flex: 1 }}
              label="endAt"
              name="endAt"
              initialValue={dayjs(attendance.endAt)}
              rules={[{
                required: true,
                message: 'Please input attendance endAt!',
              }]}>
              <DatePicker showTime style={{ width: "100%" }} />
            </Form.Item>
          </Flex>
        </Form>
      </Modal>} */}
    </Flex>
  );
}